@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: "Poppins", sans-serif !important;
  background: #ffffff !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

button {
  font-size: 14px !important;
  font-weight: 400 !important;
}
table tr th {
   height: 40px !important;
   text-align: center;
   color: #878a99 !important;
   background: #f3f6f9;
   font-size: 14px;
   font-weight: 500;
 }
table tr td {
  height: 40px !important;
  text-align: center;
  color: #212529 !important;
  font-size: 14px;
  font-weight: 400;
}
h6{
  font-size: 14px ;
}
h4 {
  font-size: 22px !important;
}
.car-details {
  background-color: #ffffff !important;


}
.car-details h4{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 28px;
  margin-left: auto;
  color: #070707;

}
.car-details{
  font-family: 'Muli', sans-serif !important;
}
.car-details h5{
  display: block;
  text-align: left;
  font-size: 23px;
  line-height: 150%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  -webkit-transition: all 300ms ease;
  font-weight: 500;
}

.bid-list {
  background: #f3f6f9;
  border: 1px solid silver;
  padding-left: 1%;
  margin-top: 2%;

}
.bid-list span{
  margin-left: 2%;
}
.bid-list i{
  margin-left: 2%;
}
.card-header {
  background: silver !important;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  color:  black;

}
.card-header h6 {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.card {
}
.card:hover {
  border-radius: 8px;
}
.form-control {
  margin-top: 5px;
  height: 50px;
}
table tr td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
table tr td a {
  line-height: 25px;
  text-decoration: none;
}
table tr td:last-child{
  font-weight: 500;
  text-align: right;
}
table tr td:first-child{
  text-align: left;
}
.auction-info {
  background: #f4f5f7;
  padding: 1rem;
  border-radius: 10px;
}
.auction-info h6{
  font-weight: 400;
  font-size: 14px;
  color: #a4a4a4;
}
/*-----Top Nav-----*/
.left-icons span {
  margin-left: 2%;
  font-size: 14px;
}
.logo {
  height: 50px;
  width: 150px;
}
.top-nav {
  background: #38454a !important;
  color: white;
}
.top-nav span {
  margin-left: 2%;
}
.nav-link {
  display: flex;
  align-items: center;
}
/*------Header-----*/
 .active .nav-link {
  color: red !important;
   font-weight: 500;
}
.navbar-brand img {
  height: 40px;
  width: 150px;
}
.nav-link {
  color: black !important;
  font-weight: 400;
}
.navtop {
  box-shadow: 0 1px 2px rgba(56,65,74,.15)
}
table .text-primary {
  background: #e7f9f0 !important;
  color: #67d99f !important;
  font-weight: 400;
  font-size: 12px;
}
table tr td {
  padding-left: 2%;
}

/*----Home ----- */
.landing {
  background: #f0f0f1;
}
.landing .col-md-4{
  padding-left: 4rem;
}
.mobile .landing .col-md-4{
  padding-left: 0;
}
.mobile .about-us {
  background-image: url(/static/media/wrapper-section-01.764a8d1f.png);
  padding: 2rem 0rem;
}
.about-us {
  background-image: url(/static/media/wrapper-section-01.764a8d1f.png);
  padding: 6rem 0rem;
}
.landing .image-section {
  min-height: 80vh !important;
  background-image: url(/static/media/ronnie-george-z11gbBo13ro-unsplash.6e92f619.jpg) !important;
  z-index: 100 !important;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;


}
.landing h1{
  font-size: 100px !important;
  font-weight: bold;
}
.tabs-section {
  padding: 2%;
  border-radius: 30px;
}
.tabs-section .btn{
  margin: 1%;
}
.tabs-section .salvages {
  background: #c4c4c4;
  padding: 3% !important;
  height: 3.75rem;
  width: 100%;
}

.tabs-section .salvages:hover {
  background: #8e8e8e;
  border-radius: 30px;
  padding: 1.5%;
  transition: ease-in-out 0.5s;
}
.tabs-section .cars {
  background: #ffe289;
  padding: 3% !important;
  height: 3.75rem;
  width: 100%;
}
.tabs-section .cars:hover {
  background: #ffc81e;
  border-radius: 30px;
  padding: 1.5%;
  transition: ease-in-out 0.5s;
}
.tabs-section .property {
  background: #ff9089;
  padding: 3% !important;
  height: 3.75rem;
  width: 100%;
  border-radius: 100px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.28);
  font-size: 18px;
  color: #070707;
  font-weight: 500;
}
.tabs-section .property:hover {
  background: #ff2b1e;
  border-radius: 30px;
  padding: 1.5%;
  transition: ease-in-out 0.5s;
}

/*------Cars--------*/
.car-img img {
  height: 280px;
  width: 100%;
}
.car-details h6{
  color: #f38e24;
  font-size: 16px;
  font-weight: 600;
}
.car-details span {

  font-weight: 400;
  margin-right: 5px;
  border-radius: 4px;
  padding: 2px 5px;

}
.car-details .views {
  color: #413F42;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.view-item {
  background: #50577A;
  height: 2.75rem;
  width: 50%;
  border-radius: 0px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.18);
  font-size: 18px;
  color: white;

}
.view-item:hover {
  background-color: #FF731D;
  color: white;
  transition: ease-in-out .2s;
}
.cars-component h3 {
  color: #222732;
}
.cars-component {
  background-color: #eff3fa;
}
.cars-component .col-md-3 {
  margin-top: 2% !important;
}
.cars-component .card {

  border: 1px solid rgba(119, 119, 119, 0.2);
}

.section-title {
  font-weight: bold;
  color: #3c5578;
  text-align: center;
}
.top-details h3 {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
}
.top-details h6 {
  color: gray;
  font-size: 14px;
}
.top-details li {
  display: inline-block;
  margin-right: 1rem;

}
.top-details ul {
  list-style-type: decimal;
}
.details-img img {
  width: 100%;
  height: 300px;
  border-radius: 20px;

}
.item-details .card {
  padding: 4%;
  background: white !important;
}
.company-heading h5 {
  color: #3c5578;
}
.company-heading h6 {
  color: gray;
  font-size: 12px;
}
.item-details input{
  height: 50px !important;
}
.item-details button {
  height: 50px;
  background: #7a63f1;
  color: white;
  width: 100%;
}
.overview-title {
  color: #3c5578;
  font-weight: bold;
}
.item-details table tr {
  border-bottom: 1px solid silver;
}
.item-details table tr td {
  height: 40px;
}
.overview-details {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
}


/*-----Contacts----*/
.contacts-section input{
  height: 50px !important;
}
.contacts-section h4 {
  color: #3c5578;
  font-weight: bold;
}
.contacts-section label {
  color: #3c5578;
  font-weight: bold;
}
.contacts-section button {
  height: 50px;
}
.contacts-section h6 {
  font-size: 14px;
  color: black;
}

/*-----Auctions----*/
.sal {
  background: #3c5578 !important;
  color: white;
  border-radius: 0;
}
.sal:hover {
  color: white !important;
}
.auctions table tr .options-table-header {

}
.auctions label {
  font-size: 14px;
}
.auctions table tr td {
  height: 60px;
}
.btn-save {
  background: #1746A2 !important;
  color: white;
  font-size: 14px;
  font-weight: 500 !important;
  padding: 10px 20px;
  border-radius: 0;
}
.btn-save:hover {
  background: white !important;
  color: #1746A2;
  border: 1px solid #1746A2;
  transition: .5s ease-in-out;
}
.table > :not(:first-child) {
  border-top: 1px solid silver;
}
/*.auctions table tr {
  border: none !important;
}*/
/*------Dashboard ------*/
.stats .card {
  box-shadow: 0 1px 2px rgba(56,65,74,.15);
  border-top: 6px solid #5f9df7;

}
.stats .card h6 {
  color: #1746A2 !important;
  text-transform: uppercase;
  font-weight: 400;
}
.stats .card h3{
  color:#FF731D !important;

}
.card-body h5 {
  color: #1746A2 !important;
}
.card {
  border: 0;
}
/*-----Authentication------*/
.authentication .card-header p {
  font-size: 14px;
  color: #5f6973;
}
.authentication .card-body p {
  font-size: 14px;
  color: #5f6973;
}
.authentication label {
  font-size: 12px !important;
}
.authentication img {
  width: 50%;
  height: 70px;
}
/*-----Terms------*/
.terms-list li{
  margin-top: 16px;
}
.terms-list {
  list-style-type: decimal;
}
/*----Footer--------*/
footer{
  background: #23252d;
  color: white;
}
footer .nav-link{
  color: white !important;
}

.carousel-inner img {
width: 100%;
  object-fit: cover;
  object-position: left;
  height: 300px;
}
.car-img img {
  width: 100%;
  object-fit: cover;
  object-position: left;
}


/*--------About--------*/
.about .card-body {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}
.about .card-body:hover {
  transition: .3s ease-in-out;
  -webkit-transform: translate(10px, -10px);
          transform: translate(10px, -10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}

/*-----Dashboard----*/
.table-title {
  font-size: 25px;
  color: #1746A2;
  font-weight: 500;
}

.stats-counter {
  padding: 3rem 0 ;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 20%) 10px 0px 35px;
}
.mobile .stats-counter {
  padding: 0rem 0 ;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 0px;
  box-shadow: none;
}
.mobile .stats-counter h4 {
  font-size: 30px !important;
  font-weight: 500;
  text-align: center;
  z-index: 1000 !important;
  color: rgb(227, 75, 29);
}
.mobile .stats-counter h6 {
  text-align: center;
  font-weight: 400;
  color: #6B728E;
  font-size: 16px !important;
}
.stats-counter h4 {
  font-size: 50px !important;
  font-weight: 500;
  text-align: center;
  z-index: 1000 !important;
  color: rgb(227, 75, 29);
}
.stats-counter h6 {
  text-align: center;
  font-weight: 400;
  color: #6B728E;
  font-size: 20px !important;
}
/*-------Mobile------*/
@media only screen and (max-width: 600px) {
  .landing {
    min-height: 50vh !important;
    z-index: 100 !important;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 5%;


  }
}
